import React from "react"
import styled from "styled-components"

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

import "react-accessible-accordion/dist/fancy-example.css"

import siteJSON from "../constants/sites.json"
import colors from "../utils/colors"
import Layout from "../components/layout"
import SEO from "../components/seo"
import arrowDown from "../images/svg/arrow-down.svg"
import ogImage from "../images/image_rekryterautvecklare.png"
import { PageSubTitle,PageTitle, PostText, Container } from "../utils/common-styles"

const PostTitle = styled.h3`
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 10px;
  color: ${colors.DARK_BLUE};
`

const Icon = styled.img`
  margin: 0;
`

const QuestionIconContainer = styled.div`
  text-align: center;
  width: 26px;
  height: 26px;
  background: ${colors.GREEN};
  border-radius: 100%;
  margin-right: 30px;
`

const AccordionStyled = styled(Accordion)`
  border: none;
`

const AccordionButtonStyled = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  color: #071357;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  padding: 20px 0 20px 0;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`

const IndexPage = () => {
  return (
    <Layout quizType="general">
      <SEO
        title="Här är de topp 10 bästa platserna att hitta en utvecklare"
        description="Vi har samlat de bästa platserna för att hitta en utvecklare. Gå igenom vårt Quiz och få reda var du bäst hitta utvecklare."
        image={ogImage}
      />
      <Container>
        <PageTitle>
          Här är de topp 10 bästa platserna att hitta en utvecklare
        </PageTitle>

        {Object.entries(siteJSON).map(([key, val], index) => (
          <React.Fragment key={key}>
            <PostTitle>{`${index + 1}. ${key}`}</PostTitle>
            <PostText>
              {val.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </PostText>
          </React.Fragment>
        ))}

        <PageSubTitle>
          Hur väljer du den rätta sajten för att anlita utvecklare?
        </PageSubTitle>

        <PostText>
         <p>Det viktiga är att först tänka igenom vad du har för behov och vilka resurser du har. Frågor som du kan ställa dig är:  Behöver du en utvecklare inom 2 veckor eller inom 6 månader? Behöver du en junior eller senior utvecklare? Är det en heltidsposition eller har du bara behov av någon som jobbar med ditt projekt deltid? Har du själv kompetensen att avgöra om en utvecklare är duktig eller inte?</p>
<p>
Vi har skapat en Quiz som tar dig igenom ett antal frågor och rekommenderar de sajter som passar bäst för dig. 
</p>
        </PostText>

        <PageSubTitle>Vanliga frågor</PageSubTitle>

        <AccordionStyled allowMultipleExpanded={true} allowZeroExpanded={true}>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionButtonStyled>
                <QuestionIconContainer>
                  <Icon src={arrowDown} alt="" />
                </QuestionIconContainer>
                <span>Vad kostar det anlita en utvecklare?</span>
              </AccordionButtonStyled>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Det finns såklart inget absolut svar som gäller i alla situationer. Men det som påverkar kostnaden är 1) vilken programmeringspråk det rör sig om (tex är lönerna för Golang-utvecklare högre än lönerna för tex PHP även om det alltid finns undantag) 2) var i världen som personen befinner sig (om man rekryterar utvecklare på distans utomlands kan det bli billigare än om man bara rekryterar utvecklare i Sverige) och 3) hur duktig och erfaren utvecklaren är. 
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionButtonStyled>
                <QuestionIconContainer>
                  <Icon src={arrowDown} alt="" />
                </QuestionIconContainer>
                <span>Var kan jag hitta utvecklare?</span>
              </AccordionButtonStyled>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Det finns många sajter och företag där du kan hitta utvecklare. Det som skiljer dem åt är vilka krav på din kompetens och din tid som ställs. 
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionButtonStyled>
                <QuestionIconContainer>
                  <Icon src={arrowDown} alt="" />
                </QuestionIconContainer>
                <span>Hur hittar jag duktiga utvecklare?</span>
              </AccordionButtonStyled>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Om du har kompetensen att avgöra vem som är en duktig utvecklare kan du själv lägga ut annonser och genomföra intervjuprocessen. Om du inte har den kompetensen bör du vända dig till en konsultförmedlare eller en rekryteringsfirma som inriktar sig på utvecklare.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </AccordionStyled>
      </Container>
    </Layout>
  )
}

export default IndexPage
